/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { Link } from 'react-router-dom';
import work from './data/work.json';

import { Typewriter } from 'react-simple-typewriter'

// const View = React.lazy(() =>
//     work.map((item => import(`./views/${item.component}.js`))),
//     work.map((item => console.log(`${item.component}`)))
// )

function Projects(props) {
    return (
        <div className="Projects">
            <div className="intro">
            <div className="title-section" sx={{ margin: '30vh 0 30vh 0', display: 'flex', flexDirection: 'column', gap: 0, lineHeight: 1 }}>
                <h1 sx={{ margin: '0 0 .5rem 0', lineHeight: 1 }}>Yicheng Feng</h1>
                <h1 sx={{ color: '#a0a0a0 ', margin: 0, lineHeight: 1 }}>
                New York-Based&nbsp;
                    <Typewriter
                        sx={{ color: '#000 !important' }}
                        words={['Designer', 'Photographer', 'Electronic Music Producer', 'Front-End Developer']}
                        loop
                        cursor
                        cursorStyle='_'
                    />
                </h1>
            </div>
            <h5>Browse Projects ↓</h5>
        </div> 
            <div className="project-wrapper">
                {
                    work.map((item =>
                        <Link key={item.index} to={"/" + item.slug} className="project-image-wrapper">
                            <div className="project-image" id={item.id} style={{backgroundImage: `url("/img/work/${item.src}")`}}>
                                <div className={'text-hover-wrapper'} style={{color: `${item.hoverColor}`}}>
                                    <div className="text-hover">
                                        <h3>{item.title}</h3>
                                        <h5>{item.year}</h5>
                                        {
                                            (item.tags || []).map(tag => (
                                                    <h5 key={tag} className="tag">{tag}</h5>
                                                ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <h4 className="project-text-mobile" sx={{color: 'text'}}>{item.title}</h4>
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}
export default Projects;