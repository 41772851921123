/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { EntypoGithubWithCircle, EntypoLinkedinWithCircle, EntypoMailWithCircle, EntypoInstagramWithCircle, EntypoSpotifyWithCircle } from "react-entypo"

function About(props) {
    return (
        <div className="About">
            <div className="about-wrapper">
                <h1>Yicheng Feng</h1>
                <div className="about-description">
                    <p>Hey there! I am currently a partner at&nbsp;
                        <a href="https://xfusor.org" sx={{ textDecoration: 'underline', color: 'accent'}}>X Fusor Design Labs</a>,
                        {/* formerly at <a href="xacademy.cc" sx={{ textDecoration: 'underline', color: 'accent'}}>X Academy</a> */}
                        <br/>
                        and a student studying Communication Design at Parsons School of Design.
                        <div style={{ marginTop: '.5rem '}}>
                            I'm a creative enthusiast that enjoys learning and creating with different tools, primarily focusing on UI/UX and graphic design, photography, cinematography, electronic music, along with front-end development and 3D graphics.
                        </div>
                    </p>
                </div>
                <div style={{ fontSize: '3em' }}>
                    <a href="mailto:yicheng@tigerfeng.cn" sx={{ textDecoration: 'none', color: 'accent', marginRight: '.25em' }}><EntypoMailWithCircle /></a>
                    <a href="https://instagram.com/tigerfeng_sh/" sx={{ textDecoration: 'none', color: 'accent', marginRight: '.25em' }}><EntypoInstagramWithCircle /></a>
                    {/* <a href="https://github.com/TigerFeng37" sx={{ textDecoration: 'none', color: 'accent', marginRight: '.25em' }}><EntypoGithubWithCircle /></a> */}
                    <a href="https://linkedin.com/in/yicheng-feng/" sx={{ textDecoration: 'none', color: 'accent', marginRight: '.25em' }}><EntypoLinkedinWithCircle /></a>
                    <a href="https://open.spotify.com/artist/4ugDSzUCMDXCcPlvbH4nic" sx={{ textDecoration: 'none', color: 'accent' }}><EntypoSpotifyWithCircle /></a>
                </div>
                <h6 sx={{marginTop: '2em', color: 'gray'}}>Website built w/ React.js & 💛</h6>
            </div>
        </div>
    )
}

export default About;